@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Staatliches', cursive;
  background-color: black;
}

#root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.App {
  width: 90vw;
  height: 400px;
  overflow: scroll;
  text-align: center;
  min-width: 311px;
}

.imageList {
  height: 600px;
}

h1 {
  position: relative;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  letter-spacing: 5px;
  width: 100vw;
  text-align: center;
  margin-bottom: 5px;
}

h2 {
  position: relative;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(243, 242, 242, 0.847);
  letter-spacing: 5px;
  font-size: 0.8em;
  text-align: center;
}

h3 {
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 50%;
  transform: translateY(50%);
  color: white;
  letter-spacing: 7px;
  width: 100%;
}

p {
  font-family: 'Staatliches', cursive !important;
}

span {
  color: crimson;
}

@media (min-width: 1025px) {
  .imageList {
    height: auto;
    overflow-x: hidden;
  }

  .App {
    overflow-x: hidden;
    height: 500px;
  }
}
